.admin-chat-container {
    display: flex;
    height: 100vh;
  }
  
  .chat-list {
    width: 30%;
    background-color: #f1f1f1;
    padding: 10px;
    border-right: 1px solid #ccc;
    overflow-y: auto;
  }
  
  .chat-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chat-item:hover {
    background-color: #ddd;
  }
  
  .selected {
    background-color: #bbb;
  }
  
  .chat-window {
    width: 70%;
    padding: 20px;
    background-color: #fff;
    overflow-y: auto;
  }
  
  .messages {
    margin-top: 20px;
  }

  .messages p {
    color: black;
    font-size: 1rem;
  }
  
  .message {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .message span {
    font-size: 0.8em;
    color: #888;
  }
  