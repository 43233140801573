.snake-game {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: 94vh;
    box-sizing: border-box;
    font-family: "Electrolize";
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(20, 20px);
    grid-template-rows: repeat(20, 20px);
    gap: 1px;
  }
  
  .row {
    display: contents;
  }
  
  .cell {
    width: 20px;
    height: 20px;
    background-color: #e0e0e0;
  }
  
  .snake {
    background-color: #000000;
  }
  
  .food {
    background-color: #00ff00;
  }
  
  .game-over {
    margin-top: 20px;
    text-align: center;
  }
  
  .game-over p {
    font-size: 24px;
    color: #333;
  }
  
  .game-over button {
    margin-top: 10px;
    padding: 8px 16px;
    font-size: 18px;
    cursor: pointer;
    font-family: "Electrolize";
  }
  
  .controls {
      height: 15rem;
      width: 80%;
      display: flex;
      justify-content: center;
      flex-direction: column;
  }

  .control-button{
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      border:none;
   
  }

  .left-right{
/*     width: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .up-down{
      display: flex;
      justify-content: center;
      align-items: center;
  }

  #up-arrow{
      transform: rotate(90deg);
      height: 2rem;
  }

  #right-arrow{
    transform: rotate(180deg);
    height: 2rem;
}
    #down-arrow{
        transform: rotate(-90deg);
        height: 2rem;
    }
    #left-arrow{
        height: 2rem;
    }