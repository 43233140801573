/* BootScreen.css */

.boot-screen {
    position: fixed; /* Fixera boot screen till skärmen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000; /* Svart bakgrund */
    color: #fff; /* Vit text */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Högt z-index för att vara ovanpå allt annat */
    transition: opacity 1s ease-in-out; /* Animation för att göra den osynlig */
    opacity: 1;
}

.boot-screen.hide {
    opacity: 0; /* Gör boot screen osynlig när den ska försvinna */
    pointer-events: none; /* Förhindra interaktion med boot screen när den är gömd */
}

.loader {
    display: inline-block;
    width: 80px;
    height: 80px;
    border: 8px solid rgba(255, 255, 255, 0.2); /* Bakgrundsfärg för spinner */
    border-radius: 50%;
    border-top: 8px solid #fff; /* Spinner-färg */
    animation: spin 1.5s linear infinite; /* Rotation animation */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.message {
    font-family: "Electrolize";
    font-size: 1.5rem;
    margin-left: 20px;
    color: #fff;
}
