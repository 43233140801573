.back-wrapper{
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 2rem;
    top: 5rem;
    height: 3rem;
    width: 5rem;
/*     background-color: pink; */
    font-family: "Electrolize";
    cursor: pointer;
}

.back-wrapper h2 {
    font-size: 1rem;
    font-weight: 100;
}

#back-arrow{
    height: 2rem;
}