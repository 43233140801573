.about-me-wrapper{
    font-family: "Electrolize";
    width: 100%;
}
.hero-section{
    display: flex;
    font-family: "Electrolize";
    background-image: url("/public/philip-no-background.jpg");
    width: 100%;
    height: 60vh;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: right 50% bottom 90%;

}

/* .hero-section {
    display: flex;
    position: relative; 
    font-family: "Electrolize";
    width: 100%;
    height: 70vh;
    overflow: hidden; 
    background-color: black;
  } */

/*   .background-video {
      display: flex;
    position: absolute;
    top: 30%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translate(-50%, -50%);
    object-fit: cover; 
    opacity: 1; 
    
  } */
.hero-section-heading{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.856);
     backdrop-filter: brightness(0.5) grayscale(.7) blur(1px); 
    padding: 2rem;
    position: relative; 
    z-index: 1;
}

.heading-name{
font-size: 3rem;
font-weight: bolder;
letter-spacing: 2px;
color: rgb(255, 255, 255);

text-shadow: -1px 0px 1px rgb(253, 210, 210, 0.3) ;
}

.heading-text{
font-weight: bolder;
letter-spacing: 2px;
color: rgb(255, 255, 255);
color:rgb(255, 94, 0);
text-shadow: -1px 0px 1px rgb(253, 210, 210, 0.3) ;
}


.about-section{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
   background-color: rgba(255, 255, 255, 0.863);

}

.about-me-text{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
 /*    align-items: flex-start; */
    width: 90%;
    color: rgb(0, 0, 0);
    max-width:40rem;  
    height: auto;
}
.about-me-text p{
    margin-bottom: 2rem;

}

.video-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
}

.video-container{
    margin-top: 1rem;
    width: 100%;
}

.title{
    color: rgb(255, 94, 0);
    letter-spacing: 2px;
}

#link{
    color:rgb(255, 94, 0) ;
}

.reach-out{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.reach-out-button{
    font-family: "Electrolize";
    width: 100%;
    height: 3rem;
    color: rgb(255, 94, 0);
    font-size: 1rem;
    background-color: transparent;
    border: 1px solid rgb(255, 94, 0);
    letter-spacing: 4px;
    font-weight: 600;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.3s ease;
}

.reach-out-button:hover{
    transform: scale(1.01)
}


.project-start{
    font-weight: bolder;
    color: rgb(255, 94, 0);
}

#project-start-paragraph{
    margin-top: 1rem;
}