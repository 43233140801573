.privacy-policy-wrapper{
    width: 100%;
    height: auto;
    font-family: "Electrolize", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: beige;
}

.privacy-policy-intro{
    margin-top: 2rem;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 80%;

}


.privacy-policy-item{
    margin-top: 2rem;
    width: 80%;
    height: auto;
}

.bold-p{
    font-weight: bolder;
}