.cookie-wrapper {
    font-family: "Electrolize";
    width: 100%;
    height: 100%;
}

.thank-you {
    position: fixed; 
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0); 
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    z-index: 999;
    color: white;

}

.thank-you.show2{

    animation: growAndFade .8s ease-in-out; 
}

@keyframes growAndFade {
    0% {
        transform: translate(-50%, -50%) scale(0.5);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(5);
        opacity: 0;
    }
}

.cookie-banner {
    font-family: "Electrolize";
    position: fixed;
    bottom: -100%;  
    width: 100%;
    background-color: #333;
    color: white;
    padding: 15px;
    text-align: center;
    z-index: 1000;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: 20%;
    transition: bottom 0.9s ease-in-out;
}

.cookie-banner.show1 {
    bottom: 0; /* Flytta upp bannern när den ska visas */
}

.cookie-buttons button {
    font-family: "Electrolize";
    width: 20rem;
    height: 2rem;
    border: none;
    border-radius: 10px;
    background-color: green;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}
