
.wrapper {
    font-family: "Electrolize";
    width: 100%;

}


.cv-container {
    width: 100%;
/*     display: flex;
    flex-direction: column; */
}

.top-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-family: "Electrolize";
    background-image: url("/public/philip2.jpg");
    width: 100%;
    height: 60vh;
    background-size:cover;
    background-position: bottom 65% right 35%;
    padding: 2rem;
    backdrop-filter: blur(50px);
}

.top-column h1 {
    font-family: "Electrolize", sans-serif;
    color: white;
    font-size: 3rem;
}

.top-column h2 {
    font-family: "Electrolize", sans-serif;
    color: white;
    font-size: 1rem;
}


/* .cv-pic {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 1px white;
} */


.cv-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.cv-header h1{
    font-size: 2rem;
    letter-spacing: 2px;
}

.cv-header h2{
    font-size: 2rem;
    color: rgb(255, 94, 0);
    font-style: italic;
    letter-spacing: 2px;
}

.contact-heading {
    display: flex;
    justify-content: center;
}

.contacts {

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.contact-item{
    display: flex;
    width: 100%;
    max-width: 21rem;
    padding: .5rem;
    border-radius: 10px;
    margin-bottom: .5rem;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.438);
/*     justify-content: flex-start; */
}

.contact-item h2{
    font-size: .8rem;
}

.contact-icon{
    color: rgb(255, 94, 0);
    height: 15px;
    margin-right: 1rem;
}


.bottom-column {
    font-family: "Electrolize", sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto; 
}


.about-me {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
}

.cv-description {
    margin-top: 1rem;
    width: 50%;
}

.experience {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
}


.experience-item {
    width: 50%;
    margin-top: 1rem;
}

.heading{
    color:  rgb(255, 94, 0);
}

.item-heading h1 {
    font-weight: bolder;
    font-size: 1.2rem;
}

.item-description p {
    font-weight: 100;
    font-size: 1rem;
}

.duration {
    letter-spacing: 2px;
    font-style: italic;
    color: rgb(141, 141, 141);
}


.education {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
}

.education-heading{
    color: rgb(255, 94, 0);
}


.skills {
    font-family: "Electrolize", sans-serif;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
}

.skills h1{
    color: rgb(255, 94, 0);
}

.skills-items {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    width: 50%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    gap: 1rem;
}

.skills-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 3rem;
    color: black;
/*     border: 1px solid  rgb(0, 0, 0); */
    box-shadow: 0px 0px 20px 1px rgb(236, 236, 236);
    border-radius: 5px;
}

.skills-item h2 {
    font-size: 1rem;
    font-weight: 900;
}

.skills-icon{
    margin-left: .5rem;
    height: 1.2rem;
/*     width: 2rem; */
}

.skills-icon-solo{
    width: 7rem;
    height: 2.2rem;
}

.skills-img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    padding: 2px;
}
/* Follow mouse button styling */
.follow-mouse-button {
    font-family: "Electrolize", sans-serif;
    position: fixed;
    font-weight: bolder;
    letter-spacing: 2px;
    font-size: 1rem;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: rgb(0, 255, 0);
    color: black;
    border:none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1000;
}

.follow-mouse-button:hover {
    cursor: pointer;
}


@media screen and (max-width: 768px) {
    .cv-description,
    .experience-item,
    .skills-items {
        width: 80%;
    }

    .skills-item {
        width: 45%;
    }
}

.fixed-button {
    font-family: "Electrolize", sans-serif;
    position: static;
    font-weight: bolder;
    letter-spacing: 5px;
    font-size: 1rem;
    padding: 10px 20px;
    background-color: transparent;
    color: black;
    border: 2px solid rgb(0, 255, 0);
    border-radius: 10px;
    cursor: pointer;
    width: 60%;
    height: 3rem;
    z-index: 1000;
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .cv-container {
      flex-direction: column;
    }
  
    .top-column {
      text-align: center;
    }
  
    .bottom-column {
      text-align: left;
    }
  
    .skills-items{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
    }

    .skills-item{
        width: 45%;
    }
    .follow-mouse-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width:10rem;
    }
  }

  .fade-in {
    opacity: 0;
    transform: translateY(20px) scale(.95);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .fade-in.visible {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  .password-container{
      width: 100%;
      height: 50vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-family: "Electrolize";
      gap: 1rem;
  }
  .password-container h2{
 font-size: 1.5rem;
  }
  .password-container input{
      width: 20rem;
      height: 2rem;
      font-family: "Electrolize";
      border-bottom: 1px solid black;
      outline: none;
      background-color: transparent;
      border-style: none;
      border-bottom: 1px solid black;
  }

  .password-container button{
    position: relative;
    width: 10rem;
    height: 2rem;
    font-family: "Electrolize";
    background-color: transparent;
    border: none;
    border: 1px solid rgb(255, 94, 0);
    font-size: 1rem;
    font-weight: bolder;
    cursor: pointer;
}

.error-message {
    text-align: center;
    position: absolute;
    color: red;
    margin-top: 15rem;
  }