.header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
    width: 100%;
    color: rgb(0, 0, 0);
    background-color: rgb(50, 50, 51);
}


.header-container h1 {
    font-family: "Electrolize", sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    padding-left: .5rem;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    text-shadow: 1px 1px 40px white;
    cursor: pointer;
}

.language-select{
    width: 1rem;
    height: 1rem;

}
.flag{
    height: 1rem;
    width: 1.5rem;
    cursor: pointer;

}
.time-date{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 10rem;
    height: auto;
}

.time-date h2 {
    font-family: "Electrolize", sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    color: rgb(255, 255, 255);
}