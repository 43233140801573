.music-player {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5rem;
  width: 100%;
  height: 100dvh;
  box-sizing: border-box;
  background-color: rgb(50, 50, 51);
}

.music-controls {
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width: 20rem;
  min-width: 20rem;
  justify-content: space-between;
  align-items: center;
}

.title-row{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.heart-icon{
  height: 1.2rem;
  cursor: pointer;
}

.heart-icon.liked{
  color: red;
}

.track-info {
  width: 80%;
  max-width: 20rem;
  min-width: 20rem;
  margin-bottom: 4px;
}

.play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  border: none;
  background-color: rgb(99, 98, 98);
  box-shadow: 0px 0px 6px 1px rgb(206, 206, 206);
  cursor: pointer;
}

.next-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  border: none;
  background-color: rgb(99, 98, 98);
  box-shadow: 0px 0px 6px 1px rgb(206, 206, 206);
  cursor: pointer;
}

.previous-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  border: none;
  background-color: rgb(99, 98, 98);
  box-shadow: 0px 0px 6px 1px rgb(206, 206, 206);
  cursor: pointer;
}

.mute-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  border: none;
  background-color: rgb(99, 98, 98);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.mute-button:hover {
  background-color: rgb(120, 120, 120);
}

.play-pause-icon {
  height: 2rem;
  color: white;
}

.next-previous-icon {
  height: 1rem;
  color: white;
}

.track-label {
  width: 18rem;
  height: 18rem;
  border-radius: 50%;
  z-index: 1;
  object-fit: cover;
}

.track-label.rotating {
  animation: rotation 3s infinite linear;
  -webkit-animation: rotation 5s infinite linear;
  animation-fill-mode: forwards;
}

.tape-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  object-fit: cover;
}

.white-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 2rem;
  width: 2rem;
  background-color: white;
  border-radius: 50%;
  z-index: 2;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

.track-title {
  color: white;
  font-size: 2rem;
}

.track-artist {
  color: white;
  font-size: 1rem;
}

.controls button {
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  width: 2rem;
  height: 2rem;
}

button:focus {
  outline: none;
}

.time-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin-top: 1rem;
}

.time {
  color: white;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  min-width: 20rem;
}

.progress-bar {
  width: 100%;
  height: 0.5rem;
/*   margin: 0 1rem; */
  border-radius: 5px;

  background: linear-gradient(
      to right,
      white var(--progress),
      rgb(99, 98, 98) var(--progress)
  );
  background-color: white;
  appearance: none;
  outline: none;
}

.progress-bar::-webkit-slider-thumb {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: white;
  color: white;
  cursor: pointer;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.progress-bar::-moz-range-thumb {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 10%;
  background-color: white;
  color: white;
  cursor: pointer;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.time-row{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 4px;
  font-size: .7rem;
}


.mute-wrapper{
  position: absolute;
  right: 2rem;
  top: 2rem;
}
