.desktop-container{
    position: relative;
    display: flex;
    font-family: "Electrolize", sans-serif;
    height: calc(100% - 2rem);
    justify-content: flex-start;
    width: 100%;
    background-color:rgba(255, 255, 255, 0);
    overflow: hidden;
    box-sizing: border-box;
}

.background-image{
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: calc(100dvh - 2rem);
    z-index: 0;
    filter: blur(4px);  
    transform: scale(1.01);
}


  .desktop-items {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: 20%;
    justify-content: space-evenly;
    width: 100%;
    height: calc(100dvh - 2rem);
    z-index: 5;
   padding-top: 1rem; 
  } 


.desktop-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 4rem;
  height: 6rem;
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
}




.item-pic{
    object-fit: cover;
    width: 4rem;
    height: 4rem;
    border-radius: 8px;
    box-shadow: 0px 0px 100px 1px rgb(207, 205, 205);
    background-color: transparent;
    transition: transform 0.1s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.item-pic:hover {
    transform: scale(1.1);
  }

.item-text{
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 1.2rem;
    width: 110%;
    font-size: .7rem;
    font-weight: bold;
    color: white;
    background-color: rgba(0, 0, 0, 0.226);
    border-radius: 5px;
    letter-spacing: .5px;
}


a {
    text-decoration: none;
}


#music-icon{
    background-color: transparent;
    color: rgb(225, 0, 255);
    width: 100%;
    height: 70%;
}

#linkedin-icon{
    background-color: rgba(255, 255, 255, 0.514);
    color: rgb(0, 119, 181);
    width: 100%;
    height: 100%;
    transform: scale(1.1);
}

#facebook-icon{
    background-color: rgba(255, 255, 255, 0.514);
    color: rgb(24, 119, 242);
    width: 100%;
    height: 100%;
    transform: scale(1.1);
}

#contact-icon{
    background-color: transparent;
    color: rgb(255, 172, 46);
    width: 100%;
    height: 70%;
}

#wikipedia-icon{
    background-color: transparent;
    color: rgb(255, 255, 255);
    width: 100%;
    height: 70%;
}

#lexbase-icon{
    background-color: transparent;
    color: rgb(117, 117, 117);
    width: 100%;
    height: 70%;
}

#github-icon{
    background-color: transparent;
    color: rgb(255, 255, 255);
    width: 100%;
    height: 80%;
}



@media (min-width: 736px){
        .desktop-items{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            flex-wrap: nowrap;
            gap: 2rem;  
            padding:1rem; 
        }
}