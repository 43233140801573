.form-wrapper {
    font-family: "Electrolize";
    display: flex;
    justify-content: center; /* Center the form horizontally */
    align-items: center; /* Center the form vertically */
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box; /* Ensure padding is included in total width */
  }
  
  .form-container {
    width: 100%;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: flex-start; 
    padding: 20px;
    box-sizing: border-box; 
  }
  
  .form-container label {
    width: 100%;
    margin-bottom: 0.5rem;
    color: #333;
    font-weight: 600;
    box-sizing: border-box;
  }
  
  textarea {
    resize: none;
  }
  
  .form-input {
    width: 100%;
    max-width: 40rem;
    height: 2rem;
    border: none;
    border-bottom: 1px solid black;
    margin-bottom: 1rem;
    background-color: #fff;
    padding: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
  }
  
  .form-input:focus {
    border-bottom: 1px solid #007bff;
    outline: none;
  }
  
  .form-message {
    width: 100%;
    max-width: 40rem;
    height: 7rem;
    margin-bottom: 1rem;
    padding: 8px;
    border: 1px solid black;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
    caret-color: black; /* Svart markör */
  }
  
  .form-message::placeholder {
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }

  .form-message:focus {
    border-color: #007bff;
    outline: none;
  }

  .form-message:focus::placeholder {
    opacity: 0; 
  }
  
  .send-button {
    margin-top: 1rem;
    height: 3rem;
    font-size: 1rem;
    letter-spacing: 3px;
    border: 1px solid black;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    width: 100%;
    max-width: 40rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    box-sizing: border-box;
  }
  
  .send-button:hover {
    background-color: #525252;
  }
  
  .plane-icon {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 8px;
/*     transform: translateX(0%) scale(1) rotate(0deg);
    transition: transform 2s ease;
 */
  }

  .plane-icon.sending{
    animation: fly 1s linear forwards;
  }

  @keyframes fly {
    0% {
      transform: translateX(0%) scale(1) rotate(0deg);
      opacity: 1;
    }
    25% {
      transform: translateX(25%) scale(1.1) rotate(20deg);
      opacity: .8;
    }
    50% {
      transform: translateX(50%) scale(1.2) rotate(-20deg);
      opacity: .6;
    }
    75% {
      transform: translateX(75%) scale(1.1) rotate(20deg);
      opacity: .4;
    }
    100% {
      transform: translateX(100%) scale(1) rotate(0deg);
      opacity:0;
    }
  }
  .form-radios {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
  
  .form-radios label {
    margin-right: 8px;
    font-weight: normal;
    color: #555;
  }
  
  .form-radios input {
    margin-right: 5px;
  }
  