.appleet{
    font-family: "Electrolize";
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: 98vh;
    background-color: rgb(50, 50, 51);
}

.appleet-header{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 90%;
}
.appleet p{
    color: white;
    font-size: 1rem;
}

.appleet h2{
    margin-top: 1rem;
    font-size: 1.2rem;
    color: white;
}

.appleet-iframe{
    width: 100%;
    max-width: 400px;
    height: 700px;
}

#register-link{
    color: rgb(255, 94, 0);
}