.chat-wrapper{
    font-family: "Electrolize";
    position: absolute;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: space-between;
    bottom: 3rem;
    right: 3rem;
    padding: 20px;
    width: 20rem;
    height: 30rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.391);
    z-index: 10;
    border-radius: 10px;
}


.chat-circle{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 6rem;
    right: 4rem;
    height: 4rem;
    width: 4rem;
    background-color: white;
    border-radius: 50%;
    color: black;
    cursor: pointer;
    z-index: 10;
}


.name-input{
    font-family: "Electrolize";
    border: none;
    border-radius: 10px;
    height: 2rem;
    padding-left: 10px;
}

.messages-area{
    height: 70%;
    background-color: rgba(255, 255, 255, 0.767);
    border-radius: 10px;
    overflow: auto;
}

.message-input{
    border: none;
    border-radius: 10px;
    height: 2rem;
    padding-left: 10px;
    width: 80%;
}


.message-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  /*   flex-direction: column; */
     max-width: 50%; 
    height: auto;
    margin: 8px;
}

.message-wrapper.sent {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: auto;
    text-align: left;
    align-items: flex-end;
  }

  .message-wrapper.received {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: auto;
    text-align: left;
    align-items: flex-start;
  }


.message-sender{
    color: black;

}
 .message-text{
    font-size: .9rem;
    color: black;
    background-color: rgb(162, 162, 255); 
    padding: 8px;
    border-radius: 10px;
}

.message-send-row{
    display: flex;
    justify-content: space-between;
}
 
.message-send-row button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border:none;
}
.plane-send{
    height: 1.2rem;
    width: 1.2rem;
    color: black;
}
.is-online-offline{
    display: flex;
    align-items: center;
}

.online{
    margin-left: 5px;
    width: 15px;
    height: 15px;
    background-color: rgb(2, 226, 2);
    border-radius: 50%;
}

.offline{
    margin-left: 5px;
    width: 15px;
    height: 15px;
 background-color: red;
    border-radius: 50%;
}

.new-message-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: white;
    border-radius: 50%;
    font-size: 1rem;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: 0px; 
    right: 0px; 
  }
